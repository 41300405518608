.vip {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -7px;
  bottom: 7px;
  background: url("./image/vip.png") no-repeat center center;
  background-size: 20px 20px;
  z-index: 3;
}
.user-info {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .icon-item,
  .avatar-item {
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .info-icon {
      // display: none;
      width: 30px;
      height: 30px;
      background: url("./image/xx.png") no-repeat center center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .icon-item {
    margin-right: 30px;
  }
  .icon-item-active {
    &::after {
      content: "";
      border-radius: 4px;
      width: 8px;
      height: 8px;
      background-color: #ff3a1f;
      position: absolute;
      right: 4px;
      top: 2px;
      z-index: 5;
    }
  }
}
