@import '../../theme.scss';

.select {
  background-color: #f8f9fb;
  //width: 100px;
  border-right: 1px solid rgb(214, 219, 228);
}

.search {
  width: 564px;
  height: 48px;
  display: flex;
  border: 1px solid $themeRed;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  background: rgba(248, 249, 251, 1);
  .input-box {
    flex: 1;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 0;
    .input {
      position: absolute;
      background: rgba(248, 249, 251, 1);
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-size: 16px;
      padding: 0 20px;
      box-sizing: border-box;
      // padding-left: 20px;
      &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgb(142, 142, 142);
        font-size: 16px;
      }
    }
    .image {
      position: absolute;
      width: 20px;
      right: 8px;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
    }
  }

  .button {
    background: $themeRed;
    height: 100%;
    color: white;
    width: 84px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circle {
    border-radius: 4px;
  }
}
