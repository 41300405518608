body {
  background-color: #f0f2f5;
}
// .ant-btn-primary {
//   background-color: #08aed0 !important;
//   border-color: #08aed0 !important;
// }
// .ant-btn {
//   border-radius: 6px !important;
// }
// .ant-btn-link {
//   color: #ff3a1f !important;
// }
// .ant-btn-background-ghost {
//   border-color: #ff3a1f !important;
//   color: #ff3a1f !important;
// }

// // .ant-popover-inner-content {
// //   color: white !important;
// //   background-color: black !important;
// //   cursor: pointer;
// // }
// .ant-pagination-item-active {
//   background: #ff3a1f !important;
//   border-color: #ff3a1f !important;
//   & > a {
//     color: white !important;
//   }
// }
// .ant-pagination-item {
//   & :hover {
//     border-color: #ff3a1f !important;
//   }
// }

.ant-table-thead > tr > th {
  background-color: rgb(232, 236, 244) !important;
  color: rgb(75, 85, 105);
  font-size: 14px;
}

.ant-table-tbody > tr:nth-child(even) > td {
  background-color: rgb(246, 247, 251) !important;
  font-size: 14px;
}
.ant-modal-content {
  background: transparent !important;
  box-shadow: none !important;
}
