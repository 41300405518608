@import "../../../theme.scss";

.layout {
  min-width: 1440px;
  overflow-x: auto;
}

.header {
  height: 120px;
  background-color: #fff;
  padding: 0 90px;
  &Content {
    height: 120px;
    display: flex;
    align-items: center;
  }
  &Logo {
    margin-right: auto;
    img:nth-of-type(1) {
      width: 171px;
      height: 36px;
    }

    img:nth-of-type(2) {
      width: 165px;
      height: 42px;
      margin-left: 32px;
    }
  }

  &Search {
    margin-right: 80px;
  }
}

.dl_button {
  width: 70px;
  height: 48px;
  border-radius: 6px;
  border: 1px solid #000000;
  box-sizing: border-box;
  background: #ffffff33;
  color: black;
  font-weight: 600;
  font-size: 16px;
  padding-left: 20px;
}

.zc_button {
  width: 100px;
  height: 48px;
  border-radius: 6px;
  //border: 1px solid #000000;
  box-sizing: border-box;
  background: linear-gradient(180deg, #fd1708 0%, #ff5732 100%);
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding-left: 20px;
}
