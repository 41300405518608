.pageAd {
  width: 144px;
  height: 244px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-image: url("./images/ad.png");
  box-sizing: border-box;
  cursor: pointer;
  position: fixed;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001;
  .close {
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    background-image: url("./images/close.png");
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
