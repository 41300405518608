.empty {
  width: 462px;
  margin: auto;
  &-image {
    width: 180px;
    height: 102px;
    display: block;
    margin: 0 auto 36px;
  }
  &-title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 4px;
    text-align: center;
  }
  &-subTitle {
    color: #999999;
    font-size: 14px;
    text-align: center;
    margin-bottom: 37px;
  }
  &-footer {
    text-align: center;
  }
}