$themeColor: #ff3a1f;

$themeRed: rgba(255, 58, 31, 1);

@mixin auditTitle {
  font-size: 16px;
  padding-left: 10px;
  box-sizing: border-box;
  position: relative;
  font-weight: bold;
  &::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    height: 16px;
    background: #ff3a1f;
    bottom: 0px;
    margin: auto;
    width: 4px;
    border-radius: 1px;
  }
}
