@import "../../theme.scss";

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  transition: all 500ms ease;
  &-block {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &-disabled {
    background-color: #b7b8bb !important;
    border: none !important;
  }
  &-ghost {
    background: rgba(255, 255, 255, 0.2) !important;
    border: none !important;
    color: #fff;
  }
  &-danger {
    background-color: #ff5151 !important;
    border: 1px solid #ff5151 !important;
  }
  &-success {
    background-color: #0eb85f !important;
    border: 1px solid #0eb85f !important;
  }
  &-size__default {
    height: 32px;
    padding: 0px 13px;
    font-size: 16px;
    .button-text {
      font-size: 14px;
      white-space: nowrap;
      letter-spacing: 5px;
    }
    .button-icon {
      margin-right: 12px;
    }
  }
  &-size__large {
    height: 40px;
    padding: 0px 13px;
    font-size: 16px;
    .button-text {
      font-size: 14px;
      white-space: nowrap;
    }
    .button-icon {
      margin-right: 12px;
    }
  }
  &-size__small {
    height: 28px;
    padding: 0px 10px;
    font-size: 14px;
    .button-text {
      font-size: 12px;
      white-space: nowrap;
    }
    .button-icon {
      margin-right: 10px;
    }
  }
  &-type__default {
    background: $themeColor;
    border: 1px solid $themeColor;
    color: #fff;
    &:active {
      background: $themeColor;
      border: 1px solid $themeColor;
    }
  }

  &-type__white {
    background: #fff;
    border: 1px solid #d9eaf9;
    &:active {
      background: rgba(217, 217, 217, 0.5);
      border: 1px solid rgba(217, 217, 217, 0.5);
    }
  }
  &-type__line {
    background-color: #fff;
    border: 1px solid $themeColor;
    color: $themeColor;
  }
}
