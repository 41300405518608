.modal {
  width: 187px;
  height: 150px;
  background-color: rgb(18, 22, 30);
  z-index: 4;
  .list {
    padding: 5px 10px;
    height: 33%;
    color: white;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    &:hover {
      background-color: rgb(255, 58, 31);
    }
    cursor: pointer;
    .text {
      margin-right: 15px;
    }
    .icon1 {
      width: 25px;
      height: 25px;
      background: url("../image/grzx.png") no-repeat center center;
      background-size: 100% 100%;
      margin-right: 15px;
    }
    .icon2 {
      width: 25px;
      height: 25px;
      background: url("../image/tc.png") no-repeat center center;
      background-size: 100% 100%;
      margin-right: 15px;
    }
  }
  .bg {
    background: url(../image/bg.png) no-repeat center center;
    background-size: 100% 62px;
    height: 62px;
    padding: 15px;
    position: relative;
    & > div {
      line-height: 0;
    }
    .title {
      height: 20px;
    }
    .day {
      color: white;
      font-size: 12px;
      & > span {
        font-size: 16px;
        margin-right: 5px;
      }
    }
    .renew {
      cursor: pointer;
      position: absolute;
      right: 10px;
      width: 37px;
      bottom: 22px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border-radius: 30pt;
      font-size: 12px;
      color: rgb(239, 208, 149);
      box-sizing: border-box;
      background: rgb(18, 22, 30);
    }
  }
}
